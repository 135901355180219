/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre",
    a: "a"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Durations"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for durations, use the ", _jsx(_components.code, {
        children: "theme.durations"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n\n  export const theme = {\n    durations: {\n      // ...\n      instant: '100ms',\n      'fast-in': '250ms',\n      'fast-out': '200ms',\n      'slow-in': '300ms',\n      'slow-out': '250ms',\n+     'very-slow': '400ms',\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "durations"
      }), " based on ", _jsx(_components.a, {
        href: "https://material.io/design/motion/speed.html#duration",
        children: "Google Material Design recommendations"
      }), " is already defined in default theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  durations: {\n    instant: '100ms',\n    'fast-in': '250ms',\n    'fast-out': '200ms',\n    'slow-in': '300ms',\n    'slow-out': '250ms',\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
